import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ITableDataSource } from '@suvo-bi-lib';
import { BreadcrumbsService } from '@suvo-bi-core';
import { ITaxon } from '../../../taxon/taxon.interface';
import { TaxonService } from '../../../taxon/taxon.service';
import { Subject } from 'rxjs';

@Component({
  template: `
    <suvo-core-loading-spinner *ngIf="!taxon"></suvo-core-loading-spinner>
    <ng-container *ngIf="taxon">
      <app-taxon-overview [isTopLevelTaxon]="false" [taxon]="taxon"></app-taxon-overview>
      <app-taxon-lineage [isTopLevelTaxon]="false" [taxon]="taxon"></app-taxon-lineage>
    </ng-container>
  `,
})
export class KnowledgeBaseTaxonScreenComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();

  taxon: ITaxon;
  searchResults: ITableDataSource<ITaxon>;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly breadcrumb: BreadcrumbsService,
    private readonly taxaDirectory: TaxonService,
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(async (data) => {
      if (data.id) {
        this.taxon = await this.taxaDirectory.getById(data.id);
        this.breadcrumb.setDynamicNameSwap(
          'id',
          this.taxon.taxonName + ` (${this.taxon.cprTaxonId})`,
        );
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
