<mat-card>
  <mat-card-header>
    <mat-card-title>{{ isTopLevelTaxon ? 'Top Level Taxa' : 'Lineage' }}</mat-card-title>
  </mat-card-header>
  <mat-card-content *ngIf="taxon">
    <div class="row">
      <div class="column" *ngIf="!isTopLevelTaxon">
        <h3>Parents</h3>
        <div *ngIf="!parents.length">No parents</div>

        <div class="column" *ngIf="parents.length">
          <ul>
            <li *ngFor="let taxon of parents">
              <a [routerLink]="['/knowledge-base', taxon._id]">
                {{ taxon.taxonName }} ({{ taxon.cprTaxonId }})
              </a>
              [{{ taxon.rank }}]
            </li>
          </ul>
        </div>
      </div>

      <div class="column">
        <h3 *ngIf="!isTopLevelTaxon">Direct Children</h3>
        <p *ngIf="!taxon.taxonChildren?.length">No children</p>
        <ul *ngFor="let taxon of taxon.taxonChildren">
          <li>
            <a [routerLink]="['/knowledge-base', taxon._id]">
              {{ taxon.taxonName }} ({{ taxon.cprTaxonId }})
            </a>
            [{{ taxon.rank }}]
          </li>
        </ul>
      </div>
    </div>
  </mat-card-content>
</mat-card>
