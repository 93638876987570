import { Component } from '@angular/core';
import { ITaxon } from '../../../taxon/taxon.interface';
import { TaxonService } from '../../../taxon/taxon.service';

@Component({
  template: `
    <suvo-core-loading-spinner *ngIf="!taxon"></suvo-core-loading-spinner>
    <app-taxon-lineage *ngIf="taxon" [isTopLevelTaxon]="true" [taxon]="taxon"></app-taxon-lineage>
  `,
})
export class KnowledgeBaseOverviewScreenComponent {
  taxon: ITaxon;

  constructor(private readonly taxaDirectory: TaxonService) {
    this.taxaDirectory
      .get('topleveltaxon')
      .then((taxonChildren) => (this.taxon = { taxonChildren } as ITaxon));
  }
}
