<mat-card>
  <mat-card-header>
    <mat-card-title>Overview</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <app-edit
      [formGroup]="formGroup"
      permission="taxon.edit"
      (save)="onSave($event)"
      *ngIf="taxon"
      #editComponent
    >
      <div class="row">
        <div class="input-group">
          <mat-form-field>
            <mat-label>Taxon Name</mat-label>
            <input matInput type="text" formControlName="taxonName" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Classification</mat-label>
            <input matInput readonly type="text" formControlName="rank" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>CPR Taxon ID</mat-label>
            <input matInput type="number" formControlName="cprTaxonId" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Aphia ID</mat-label>
            <input matInput type="number" formControlName="aphiaId" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>ITIS Code</mat-label>
            <input matInput type="number" formControlName="itisCode" />
          </mat-form-field>
        </div>

        <div class="input-group">
          <mat-form-field>
            <mat-label>Counting Method</mat-label>
            <mat-select name="countingMethod" formControlName="countingMethod">
              <mat-option value="EYECOUNT">Eye Count</mat-option>
              <mat-option value="TRAVERSE">Traverse</mat-option>
              <mat-option value="PHYTO">Phyto</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Life Stage</mat-label>
            <input matInput type="text" formControlName="lifeStage" />
          </mat-form-field>
          <mat-checkbox type="checkbox" formControlName="routineAnalysis"
            >Routine analysis?</mat-checkbox
          >
          <mat-checkbox type="checkbox" formControlName="isOnlyEverPlussed"
            >Is only ever plussed?</mat-checkbox
          >
        </div>

        <div class="input-group">
          <mat-form-field>
            <mat-label>Comments</mat-label>
            <textarea matInput formControlName="comments"></textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="links" *ngIf="taxon.aphiaId">
        <a
          href="https://www.marinespecies.org/aphia.php?p=taxdetails&id={{ taxon.aphiaId }}"
          target="_blank"
        >
          WoRMS Taxon Details
          <mat-icon>open_in_new</mat-icon>
        </a>
      </div>
    </app-edit>
  </mat-card-content>
</mat-card>
